import { t } from "i18next";
import {
  historyPath,
  paymentMethodsPath,
  billingPath,
  usagePath,
} from "./routes";

export const enum PageRoute {
  INDEX = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/password-reset",
  CHECKOUT_RETURN = "/checkout/return",
  DASHBOARD = "/dashboard",
  SELECT_CHAIN = "/select-chain",
  SELECT_NETWORK = "/select-network",
  SUPPORT = "/support",
  SETTINGS = "/settings",
  REGISTER = "/register",
  ENDPOINTS = "/endpoints",
  ENDPOINT = "/endpoints/:id",
  ENDPOINT_TABS = "/endpoints/:id/:tabValue",
  USAGE_BILLING_TABS = "/usage-billing/:tabValue",
  API_KEYS = "/api-keys",
  VERIFY = "/verify",
  ACCESS = "/access",
  REQUIRED_DATA = "/required",
  MAINTENANCE = "/maintenance",
  DEDICATED_NODES = "/dedicated-nodes",
}

export enum EndpointTabValue {
  GET_STARTED = "urls",
  METRICS = "metrics",
}

export enum UsageBillingTabValue {
  USAGE = "usage",
  BILLING = "billing",
  HISTORY = "history",
  PAYMENT_METHODS = "payment-methods",
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getHeaderBasedOnRoute = (
  currentRoute: string,
  args: { endpointId: string | undefined },
) => {
  switch (currentRoute) {
    case PageRoute.DASHBOARD:
      return t("root_panel.header.dashboard");
    case PageRoute.RESET_PASSWORD:
      return t("root_panel.header.reset_password");
    case PageRoute.SELECT_CHAIN:
      return t("root_panel.header.select_chain");
    case PageRoute.SELECT_NETWORK:
      return t("root_panel.header.select_network");
    case usagePath:
      return t("root_panel.header.usage");
    case billingPath:
      return t("root_panel.header.billing");
    case historyPath:
      return t("root_panel.header.usage_billing");
    case paymentMethodsPath:
      return t("root_panel.header.usage_billing");
    case PageRoute.SETTINGS:
      return t("root_panel.header.settings");
    case PageRoute.REGISTER:
      return t("root_panel.header.register");
    case PageRoute.API_KEYS:
      return t("root_panel.header.api_keys");
    case PageRoute.VERIFY:
      return t("root_panel.header.verify");
    case PageRoute.DEDICATED_NODES:
      return t("root_panel.header.dedicated_nodes");
    case PageRoute.SUPPORT:
      return t("root_panel.header.support");
    default:
      if (args.endpointId) {
        // Covers the endpoints tabs
        // getStartedPath, metricsPath
        return capitalizeFirstLetter(args.endpointId);
      } else {
        return t("root_panel.header.endpoints");
      }
  }
};

export const getEndpointTabs = (endpointId: string) => {
  return [
    {
      value: `${EndpointTabValue.GET_STARTED.replace(":id", endpointId)}`,
      title: t("root_panel.header.get_started"),
    },
    {
      value: `${EndpointTabValue.METRICS.replace(":id", endpointId)}`,
      title: t("root_panel.header.metrics"),
    },
  ];
};

export const getUsageBillingTabs = () => {
  return [
    { value: UsageBillingTabValue.USAGE, title: t("root_panel.header.usage") },
    { value: UsageBillingTabValue.BILLING, title: t("root_panel.header.plan") },
  ];
};
