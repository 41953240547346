import { FC, useEffect, useState } from "react";
import { Loading } from "../components/loading";
import { GoBackArrow } from "../icons/go-back-arrow";
import { TaxId } from "../components/tax-id";
import { CountrySelector } from "../components/country-selector";
import { Button, Flex, Select, Text, TextInput, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { selectAccessRequestState, updateUser, useUser } from "src/store/user";
import { PageRoute } from "src/utility/utils";
import { useAppDispatch } from "src/core/hooks";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { useAttributes } from "src/store/attributes";
import { SelectControlParams } from "src/api/attributes";
import { User } from "src/api/marly";
import {
  selectOutsetaAccountUpdateState,
  updateOutsetaAccount,
  useOutsetaAccount,
} from "src/store/organization";
import { OutsetaAccount } from "src/api/organization";

interface RequiredDataFormProps {
  user: User;
  account: OutsetaAccount;
  countries: { code: string; name: string }[];
}

const RequiredDataForm: FC<RequiredDataFormProps> = ({
  user,
  account,
  countries,
}) => {
  const accessRequest = useSelector(selectAccessRequestState);
  const attributes = useAttributes();
  const [formPart, setFormPart] = useState<"info" | "address">("info");
  const dispatch = useAppDispatch();
  const [taxId, setTaxId] = useState<string | undefined>(
    undefined /*account.taxId*/,
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [stripeTaxIdType, setStripeTaxIdType] = useState<string | undefined>(
    undefined,
  );
  const updateRequest = useSelector(selectOutsetaAccountUpdateState);

  const initalCountry = countries.find(
    (x) =>
      x.name.toLowerCase() === account.billingAddress?.country ?? { name: "" },
  );
  const [countryCode, setCountryCode] = useState<string>(
    initalCountry?.name ?? "",
  );

  // When created organization are named after the users email, so don't show that if it is the same
  let organizationInitialName = account.name ?? "";
  if (account.name === user.email) {
    organizationInitialName = "";
  }

  let firstNameInitial = "";
  let lastNameInitial = "";
  if (user.name.length >= 1) {
    if (user.name.includes(" ")) {
      const [first, ...rest] = user.name.split(" ");
      firstNameInitial = first;
      lastNameInitial = rest.join(" ");
    } else {
      firstNameInitial = user.name;
    }
  }

  const form = useForm({
    initialValues: {
      firstName: firstNameInitial,
      lastName: lastNameInitial,
      organization: organizationInitialName,
      usecase: account.usecase ?? "",
      otherUsecase: account.otherUsecase ?? "",
      referrer: account.referrer ?? "",

      addressLine1: account.billingAddress?.addressLine1 ?? "",
      addressLine2: account.billingAddress?.addressLine2 ?? "",
      city: account.billingAddress?.city ?? "",
      state: account.billingAddress?.state ?? "",
      postalCode: account.billingAddress?.postalCode ?? "",
    },
  });

  const handleSubmit = () => {
    const values = form.values;

    const country = countries.find(
      (x) => x.code.toLowerCase() === countryCode.toLowerCase(),
    );

    const update = JSON.parse(JSON.stringify(account));

    update.name = values.organization;
    update.usecase = values.usecase;
    if (values.usecase === "Other") {
      update.otherUsecase = values.otherUsecase;
    }
    update.referrer = values.referrer;
    update.taxId = taxId;
    update.taxIdType = stripeTaxIdType;

    update.billingAddress = {
      uid: update.billingAddress?.uid,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressLine3: update.addressLine3,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: country?.name ?? "",
      created: update.created,
      updated: update.updated,
    };

    dispatch(updateOutsetaAccount(update));
    // Fiddle around with the name here to not create a new endpoint
    dispatch(
      updateUser({
        name: `${values.firstName} ${values.lastName}`,
        tosAgreement: user.tosAgreement,
      }),
    );
  };

  let usecaseData: string[] = [];
  if (attributes.state === "fulfilled") {
    const usecase = attributes.data.find(
      (attribute) => attribute.systemName === "Usecase",
    );
    if (usecase != null) {
      const params = usecase?.controlParams as SelectControlParams;
      usecaseData = params.options;
    }
  }

  return (
    <Flex
      h="100vh"
      w="100vw"
      direction="column"
      align="center"
      justify="center"
      gap="md"
    >
      <Loading
        isLoading={
          accessRequest.state === "loading" || attributes.state === "loading"
        }
      >
        <Flex direction="column" gap="lg">
          {formPart === "info" && (
            <>
              <Flex w="100%" justify="center">
                <Title order={3}>We need some additional information</Title>
              </Flex>
              <form onSubmit={form.onSubmit(() => setFormPart("address"))}>
                <Flex direction="column" gap="lg">
                  <Flex direction="row" gap="xs" justify="space-between">
                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          First name
                        </Text>
                      }
                      placeholder={"First name"}
                      required
                      {...form.getInputProps("firstName")}
                    />
                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          Last name
                        </Text>
                      }
                      placeholder={"last name"}
                      required
                      {...form.getInputProps("lastName")}
                    />
                  </Flex>

                  <TextInput
                    styles={{
                      label: {
                        // Needed to get the required star on the same row as our custom label
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                      },
                    }}
                    label={
                      <Text size="md" fw={600}>
                        Organization name
                      </Text>
                    }
                    placeholder={
                      "If this is a business account, please enter your Organizations name"
                    }
                    required
                    {...form.getInputProps("organization")}
                  />

                  <Loading isLoading={attributes.state === "loading"}>
                    <Select
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          What are you building?
                        </Text>
                      }
                      required
                      data={usecaseData}
                      {...form.getInputProps("usecase")}
                    />
                  </Loading>

                  {form.values.usecase == "Other" && (
                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          What "Other" thing are you building?
                        </Text>
                      }
                      required
                      {...form.getInputProps("otherUsecase")}
                    />
                  )}

                  <TextInput
                    styles={{
                      label: {
                        // Needed to get the required star on the same row as our custom label
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                      },
                    }}
                    label={
                      <Text size="md" fw={600}>
                        Where did you hear about us?
                      </Text>
                    }
                    required
                    {...form.getInputProps("referrer")}
                  />

                  <Button
                    w="100%"
                    color="green"
                    type="submit"
                    loading={attributes.state === "loading"}
                    data-disabled={
                      form.values.firstName === "" ||
                      form.values.organization == "" ||
                      form.values.usecase == "" ||
                      (form.values.usecase == "Other" &&
                        form.values.otherUsecase == "") ||
                      form.values.referrer == ""
                    }
                  >
                    Continue
                  </Button>
                </Flex>
              </form>
            </>
          )}

          {formPart === "address" && (
            <>
              <Flex w="100%" justify="center">
                <Title order={3}>Please enter your address</Title>
              </Flex>
              <form onSubmit={form.onSubmit(() => handleSubmit())}>
                <Flex direction="column" gap="lg">
                  <TextInput
                    styles={{
                      label: {
                        // Needed to get the required star on the same row as our custom label
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                      },
                    }}
                    label={
                      <Text size="md" fw={600}>
                        Address line 1
                      </Text>
                    }
                    required
                    {...form.getInputProps("addressLine1")}
                  />

                  <TextInput
                    label={
                      <Text size="md" fw={600}>
                        Address line 2
                      </Text>
                    }
                    {...form.getInputProps("addressLine2")}
                  />

                  <TextInput
                    styles={{
                      label: {
                        // Needed to get the required star on the same row as our custom label
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                      },
                    }}
                    label={
                      <Text size="md" fw={600}>
                        City
                      </Text>
                    }
                    required
                    {...form.getInputProps("city")}
                  />

                  <Flex direction="row" gap="sm" justify="space-between">
                    <TextInput
                      label={
                        <Text size="md" fw={600}>
                          State/Province
                        </Text>
                      }
                      {...form.getInputProps("state")}
                    />

                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          Postal code
                        </Text>
                      }
                      required
                      {...form.getInputProps("postalCode")}
                    />
                  </Flex>

                  <CountrySelector
                    value={countryCode}
                    onChange={setCountryCode}
                  />

                  <TaxId
                    value={taxId}
                    countryCode={countryCode}
                    disabled={false /*|| !information.canChangeTaxId*/}
                    hasError={setHasError}
                    onChange={(taxId, stripeTaxIdType) => {
                      setTaxId(taxId);
                      setStripeTaxIdType(stripeTaxIdType);
                    }}
                  />

                  <Button
                    w="100%"
                    color="green"
                    type="submit"
                    loading={updateRequest.state === "loading"}
                    data-disabled={
                      form.values.addressLine1 === "" ||
                      form.values.city == "" ||
                      form.values.postalCode === "" ||
                      hasError
                    }
                  >
                    Update my information
                  </Button>
                </Flex>
              </form>

              <Button
                variant="transparent"
                color="green.6"
                onClick={() => setFormPart("info")}
                leftSection={<GoBackArrow />}
              >
                <Text size="xs"> or go back</Text>
              </Button>
            </>
          )}
        </Flex>
      </Loading>
    </Flex>
  );
};

const hasAllRequiredFields = (user: User, account: OutsetaAccount): boolean => {
  const isNotEmpty = (s: string | undefined): boolean =>
    s != undefined && s != "";
  return [
    isNotEmpty(user.name),
    isNotEmpty(account.name),
    account.name !== user.email,
    isNotEmpty(account.usecase),
    account.usecase === "Other" && isNotEmpty(account.otherUsecase),
    isNotEmpty(account.referrer),
    isNotEmpty(account.billingAddress?.addressLine1),
    isNotEmpty(account.billingAddress?.city),
    isNotEmpty(account.billingAddress?.postalCode),
    isNotEmpty(account.billingAddress?.country),
  ].every((check) => !!check);
};

export const RequiredDataPage = () => {
  const user = useUser();
  const outsetaAccount = useOutsetaAccount();
  const navigate = useNavigate();
  const [doRedirect, setDoRedirect] = useState(false);
  const [cookies] = useCookies(cookieSelector);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const loadCountries = async () => {
      const response = await fetch("https://config.outseta.com/countries.json");
      const countries = await response.json();
      setCountries(
        countries.map((c: { Code: string; Name: string }) => ({
          code: c.Code,
          name: c.Name,
        })),
      );
    };

    loadCountries();
  }, []);

  useEffect(() => {
    if (user.state === "fulfilled" && outsetaAccount.state === "fulfilled") {
      if (
        isAuthenticated(cookies) &&
        hasAllRequiredFields(user.data, outsetaAccount.data)
      ) {
        setDoRedirect(true);
      }
    }
  }, [user, outsetaAccount, cookies]);

  useEffect(() => {
    if (doRedirect) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("from");
      if (redirect) {
        navigate(redirect);
      } else {
        navigate(PageRoute.DASHBOARD);
      }
    }
  }, [doRedirect, navigate]);

  return (
    <Loading
      isLoading={user.state === "loading" || outsetaAccount.state === "loading"}
    >
      {user.state === "fulfilled" && outsetaAccount.state === "fulfilled" && (
        <RequiredDataForm
          user={user.data}
          account={outsetaAccount.data}
          countries={countries}
        />
      )}
    </Loading>
  );
};
