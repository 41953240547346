import { FC, useEffect, useState } from "react";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { DwellirLogoBackground } from "../components/dwellir-logo-background";
import { theme } from "../theme";
import { Loading } from "../components/loading";
import { EndpointsSearch } from "../components/endpoints-search";
import {
  Text,
  NavLink,
  Paper,
  Flex,
  Box,
  Progress,
  alpha,
  Center,
  Grid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useAnalyticsV4,
  useCurrentSubscription,
  useRpsAnalytics,
} from "src/store/user";

interface ViewLinkProps {
  href: string;
  label: string;
}

export const ViewLink: FC<ViewLinkProps> = ({ href, label }) => {
  const navigate = useNavigate();
  // Use a button component and onClick here to prevent a full page reload
  // which causes us to loose our redux state.
  return (
    <NavLink
      label={label}
      style={{
        borderRadius: "var(--mantine-radius-lg)",
      }}
      component="button"
      onClick={() => navigate(href)}
      active
      fw={700}
      variant="subtle"
      color="green"
    />
  );
};

interface HedingProps {
  label: string;
}

const Heading: FC<HedingProps> = ({ label }) => {
  return (
    <Flex direction="row" gap="md" align="center">
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
      <Text style={{ whiteSpace: "nowrap" }}>{label}</Text>
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
    </Flex>
  );
};

const AccountStatsCard = () => {
  const { t } = useTranslation();
  const analytics = useAnalyticsV4("day", "startOfMonth");
  const rpsAnalytics = useRpsAnalytics();
  const subscription = useCurrentSubscription();

  const [responsesThisMonth, setResponsesThisMonth] = useState<
    number | undefined
  >(undefined);
  const [progressThisMonth, setProgressThisMonth] = useState<number>(0);
  const [prognosisThisMonth, setPrognosisThisMonth] = useState<number>(0);

  useEffect(() => {
    if (analytics.state === "fulfilled" && subscription.state === "fulfilled") {
      const responsesThisMonth = analytics.data.rows.reduce(
        (acc, row) => row.requests + acc,
        0,
      );
      setResponsesThisMonth(responsesThisMonth);

      const progressThisMonth =
        responsesThisMonth / subscription.data.monthlyQuota;
      setProgressThisMonth(progressThisMonth);

      // Simple linear prognosis, if we want to do something fancier we should
      // move the logic to the backend.
      const daysInMonth = (year: number, month: number) =>
        new Date(year, month, 0).getDate();
      const today = new Date();
      const daysThisMonth = daysInMonth(
        today.getFullYear(),
        today.getMonth() + 1,
      );
      const daysSoFar = today.getDate();
      const daysLeftThisMonth = daysThisMonth - daysSoFar;

      const averageResponsesPerDay = responsesThisMonth / daysSoFar;
      const estimated = averageResponsesPerDay * daysLeftThisMonth;
      setPrognosisThisMonth(
        progressThisMonth + estimated / subscription.data.monthlyQuota,
      );
    }
  }, [analytics, subscription]);

  return (
    <Paper
      p={{ base: "md", lg: "xl", xl: "xl" }}
      radius="md"
      shadow="md"
      bg="second-background"
      mb="md"
      w="100%"
    >
      <Flex direction="row" justify="space-between">
        <Flex direction="column">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading
            isLoading={
              analytics.state === "loading" || subscription.state == "loading"
            }
          >
            <Box pb="4px">
              <Center>
                <Text
                  c="green"
                  ta="right"
                  size="calc(2.5rem * var(--mantine-scale))"
                  fw={700}
                >
                  {responsesThisMonth != null
                    ? t("format.number", {
                        number: responsesThisMonth,
                      })
                    : "-"}
                </Text>
              </Center>
            </Box>
            <Flex direction="row" w="100%" justify="flex-end">
              <Text size="md" fw={600}>
                /{" "}
                {subscription.state === "fulfilled"
                  ? t("format.number", {
                      number: subscription.data.monthlyQuota,
                    })
                  : "-"}{" "}
                RESPONSES
              </Text>
            </Flex>
            <Progress.Root size="lg" radius="md">
              <Progress.Section value={progressThisMonth} color="green" />
              <Progress.Section
                value={prognosisThisMonth}
                color={alpha(theme.colors.green[6], 0.15)}
                style={{
                  border: "1px dashed var(--mantine-color-green-6)",
                  borderLeft: "unset",
                }}
              />
            </Progress.Root>
          </Loading>
        </Flex>

        <Flex direction="column">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading isLoading={rpsAnalytics.state === "loading"}>
            <Grid columns={8} gutter="0px">
              <Grid.Col span={3}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.rps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      AVERAGE RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={2}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.yellow[3]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.peakRps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      PEAK RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={3} w="22em">
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.red[7]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.limitedRequests,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      LIMITED REQUESTS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>
            </Grid>
          </Loading>
        </Flex>

        <Flex direction="column">
          <Box pb="8px">
            <Heading label="Your Plan's RPS Limits" />
          </Box>
          <Loading isLoading={subscription.state === "loading"}>
            <Flex direction="row" gap="md">
              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.rateLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  RATE LIMIT
                </Text>
              </Flex>

              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.burstLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  BURST LIMIT
                </Text>
              </Flex>
            </Flex>
          </Loading>
        </Flex>
      </Flex>
    </Paper>
  );
};

export const Dashboard = () => {
  return (
    <RootPanel withNavbar>
      <Box h="100%" p="xl">
        <DwellirLogoBackground>
          <Flex h="100%" direction="column" p="md">
            <EndpointsSearch />
            <Flex flex="1" align="flex-end">
              <AccountStatsCard />
            </Flex>
          </Flex>
        </DwellirLogoBackground>
      </Box>
    </RootPanel>
  );
};
