import { useEffect } from "react";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import organizationAPI, {
  OrganizationInformation,
  OutsetaAccount,
} from "src/api/organization";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface OrganizationState {
  information: OrganizationInformation | null;
  create: RequestState<null>;
  get: RequestState<null>;
  update: RequestState<null>;
  outsetaAccount: RequestState<OutsetaAccount>;
  outsetaAccountGet: RequestState<null>;
  outsetaAccountUpdate: RequestState<null>;
}

const initialState: OrganizationState = {
  information: null,
  create: EMPTY_STATE,
  get: EMPTY_STATE,
  update: EMPTY_STATE,
  outsetaAccount: EMPTY_STATE,
  outsetaAccountGet: EMPTY_STATE,
  outsetaAccountUpdate: EMPTY_STATE,
};

export const createOrganizationInformation = createAsyncThunk(
  "user/createOrganizationInformation",
  async (input: OrganizationInformation, { rejectWithValue }) => {
    try {
      const request = organizationAPI.createOrganizationInformation(input);
      const response = await request;
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getOrganizationInformation = createAsyncThunk(
  "user/getOrganizationInformation",
  async (_, { rejectWithValue }) => {
    try {
      const request = organizationAPI.getOrganizationInformation();
      const response = await request;
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateOrganizationInformation = createAsyncThunk(
  "user/updateOrganizationInformation",
  async (input: OrganizationInformation, { rejectWithValue }) => {
    try {
      const response = await organizationAPI.updateOrganizationInformation(
        input,
      );
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getOutsetaAccount = createAsyncThunk(
  "user/getOutsetaAccount",
  async (_, { rejectWithValue }) => {
    try {
      const request = organizationAPI.getOutsetaAccount();
      const response = await request;
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateOutsetaAccount = createAsyncThunk(
  "user/updateOutsetaAccount",
  async (input: OutsetaAccount, { rejectWithValue }) => {
    try {
      const response = await organizationAPI.updateOutsetaAccount(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const informationSlice = createSlice({
  name: "OrganizationInformation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // *** List ***
    builder.addCase(getOrganizationInformation.pending, (state, _action) => {
      state.get = LOADING_STATE;
    });
    builder.addCase(getOrganizationInformation.rejected, (state, action) => {
      const error = action.payload as Error;

      state.get = rejectedState(error);
    });
    builder.addCase(
      getOrganizationInformation.fulfilled,
      (state, action: PayloadAction<OrganizationInformation>) => {
        state.information = action.payload;
        state.get = fulfilledState(null);
      },
    );

    // *** Create ***
    builder.addCase(
      createOrganizationInformation.pending,
      (state: OrganizationState, _action: PayloadAction<unknown>) => {
        state.create = LOADING_STATE;
      },
    );
    builder.addCase(
      createOrganizationInformation.rejected,
      (state: OrganizationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.create = rejectedState(error);
      },
    );
    builder.addCase(
      createOrganizationInformation.fulfilled,
      (state, action) => {
        state.information = action.meta.arg;
        state.create = fulfilledState(null);
      },
    );

    // *** Update ***
    builder.addCase(
      updateOrganizationInformation.pending,
      (state: OrganizationState, _action: PayloadAction<unknown>) => {
        state.update = LOADING_STATE;
      },
    );
    builder.addCase(
      updateOrganizationInformation.rejected,
      (state: OrganizationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.update = rejectedState(error);
      },
    );
    builder.addCase(
      updateOrganizationInformation.fulfilled,
      (state, action: PayloadAction<OrganizationInformation>) => {
        state.information = action.payload;
        state.update = fulfilledState(null);
      },
    );

    // Get Outseta Account
    builder.addCase(getOutsetaAccount.pending, (state, _action) => {
      state.outsetaAccountGet = LOADING_STATE;
    });
    builder.addCase(getOutsetaAccount.rejected, (state, action) => {
      const error = action.payload as Error;

      state.outsetaAccountGet = rejectedState(error);
    });
    builder.addCase(
      getOutsetaAccount.fulfilled,
      (state, action: PayloadAction<OutsetaAccount>) => {
        state.outsetaAccount = fulfilledState(action.payload);
        state.outsetaAccountGet = fulfilledState(null);
      },
    );

    // Update OutsetaAccount
    builder.addCase(
      updateOutsetaAccount.pending,
      (state: OrganizationState, _action: PayloadAction<unknown>) => {
        state.outsetaAccountUpdate = LOADING_STATE;
      },
    );
    builder.addCase(
      updateOutsetaAccount.rejected,
      (state: OrganizationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.outsetaAccountUpdate = rejectedState(error);
      },
    );
    builder.addCase(
      updateOutsetaAccount.fulfilled,
      (state, action: PayloadAction<OutsetaAccount>) => {
        state.outsetaAccount = fulfilledState(action.payload);
        state.outsetaAccountUpdate = fulfilledState(null);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectState = (state: RootState): OrganizationState => state.organization;
export const selectInformationUpdateState = (
  state: RootState,
): RequestState<null> => state.organization.update;
export const selectOutsetaAccountUpdateState = (
  state: RootState,
): RequestState<null> => state.organization.outsetaAccountUpdate;

export const useOrganizationInformation =
  (): OrganizationInformation | null => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(selectState);

    useEffect(() => {
      if (state.information == null && state.get === EMPTY_STATE) {
        dispatch(getOrganizationInformation());
      }
    }, [state, dispatch]);

    // TODO We should probably handle errors in a generic way?
    // Throw here and have a error boundary that handles it?

    return state.information;
  };

export const useOutsetaAccount = (): RequestState<OutsetaAccount> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (
      state.outsetaAccount == EMPTY_STATE &&
      state.outsetaAccountGet === EMPTY_STATE
    ) {
      dispatch(getOutsetaAccount());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.outsetaAccount;
};

// export const { } = informationSlice.actions;
export default informationSlice.reducer;
