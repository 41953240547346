import { useEffect, useState } from "react";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { EndpointsSearchResult } from "../components/endpoints-search/endpoints-search-result";
import { EndpointsSearchInput } from "../components/endpoints-search/endpoints-search-input";
import { Loading } from "../components/loading";
import { RequestChain } from "../components/requst-chain";
import { Flex, Paper } from "@mantine/core";
import { useChains } from "src/store/chain";
import { useUserApiKeys } from "src/store/api_keys";
import { ApiKey } from "src/api/api_keys";

export const EndpointsPage = () => {
  const chains = useChains();
  const [filter, setFilter] = useState("");
  const apiKeys = useUserApiKeys();
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);

  useEffect(() => {
    if (apiKeys && apiKeys.length >= 1 && apiKey == null) {
      setApiKey(apiKeys[0]);
    }
  }, [apiKeys, apiKey]);

  return (
    <Loading isLoading={apiKeys === null}>
      {apiKeys != null && (
        <RootPanel
          withNavbar
          withApiKeySelect={{
            hideLabel: true,
            selectedApiKey: apiKey,
            apiKeys,
            onSelect: (key: ApiKey) => setApiKey(key),
          }}
        >
          <Paper shadow="sm" p="md" m="md" radius="md">
            <Loading isLoading={chains.state === "loading"}>
              <EndpointsSearchInput onChange={setFilter} />
              <Flex direction="column" m="xs" gap="md">
                {chains.state === "fulfilled" && apiKey != null && (
                  <EndpointsSearchResult
                    filter={filter}
                    chains={chains.data}
                    apiKey={apiKey}
                  />
                )}
                <RequestChain initialRequest={filter} />
              </Flex>
            </Loading>
          </Paper>
        </RootPanel>
      )}
    </Loading>
  );
};
