import axios, { AxiosResponse, AxiosError } from "axios";
import packageInfo from "package.json";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";
import axiosRetry, { exponentialDelay } from "axios-retry";

export const baseURL =
  process.env.REACT_APP_MARLY_URL || "http://API_NOT_CONFIGURED";

const axiosConfig = {
  baseURL,
  timeout: 60 * 1000,
  headers: {
    "X-Dwellir-App": "Dashboard",
    "X-Dwellir-Version": packageInfo.version,
  },
};
export const marlyApi = axios.create(axiosConfig);
axiosRetry(marlyApi, { retries: 5, retryDelay: exponentialDelay });

// Separate axios instance for healthChecks as we don't want the retry logic
// there.
export const healthInstance = axios.create(axiosConfig);

const unauthenticatedInterceptor = (
  error: AxiosError,
  navigateFun: () => void,
) => {
  if (error.response?.status === 401) {
    navigateFun();
  }
  return Promise.reject(error);
};

export const AxiosInterceptorsSetup = (navigateFun: () => void) => {
  marlyApi.interceptors.response.use(undefined, (error: AxiosError) =>
    unauthenticatedInterceptor(error, navigateFun),
  );
};

export interface LoginInput {
  username: string;
  password: string;

  // Additional fields listed in the api doc that we currently don't use.
  // grant_type
  // scope
  // client_id
  // client_secret
}

// UserInput used inside of the dashboard
export interface UserInput {
  name: string | undefined;
  email: string;
  password: string;
  organization_name: string | undefined;
  usecase: string | undefined;
  otherUsecase: string | undefined;
  referrer: string | undefined;
  tosAgreement: string;
}

export interface User {
  id: string;
  email: string;
  isActive: boolean;
  isSuperuser: boolean;
  isVerified: boolean;
  isLegacy: boolean;
  createdAt: string;
  updatedAt: string;
  version: string;
  type: string;
  name: string;
  tosAgreement: string;
}

export interface AccessResponse {
  first_time: boolean; // Was this the first time the user gained access
}

export function isAxiosError<ResponseType>(
  error: unknown,
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error); // eslint-disable-line import/no-named-as-default-member
}

export type detailError = {
  detail: string;
};

type validationError = {
  detail: {
    loc: string[];
    msg: string;
    type: string;
  }[];
};

export class BadCredentialsError extends Error {
  readonly detail: string;
  constructor(detail: string) {
    super(detail);
    this.detail = detail;
  }
}

export class UserExistsError extends Error {
  readonly detail: string;
  constructor(detail: string) {
    super(detail);
    this.detail = detail;
  }
}

export class InvalidEmail extends Error {
  readonly detail: string;
  constructor(detail: string) {
    super(detail);
    this.detail = detail;
  }
}

export class PasswordToShort extends Error {
  readonly detail: string;
  readonly minLength: number;
  constructor(detail: string, minLength: number) {
    super(detail);
    this.detail = detail;
    this.minLength = minLength;
  }
}

export class DisposableDomainBlocked extends Error {}

const login = async (input: LoginInput): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post("/v4/auth/login", input, {
      withCredentials: true,
    })
    .catch((error) => {
      if (isAxiosError<detailError>(error)) {
        throw new BadCredentialsError(
          error.response?.data.detail || "Missing detail",
        );
      } else {
        // Unknown error
        console.error("Unknown login error", error);
        throw error;
      }
    });
};

const access = async (
  token: string,
): Promise<AxiosResponse<AccessResponse>> => {
  return await marlyApi
    .post("/v4/auth/access", { token }, { withCredentials: true })
    .catch((error) => {
      // Unknown error
      console.error("Unknown login error", error);
      throw error;
    });
};

const logout = async (): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post("/v4/auth/logout", null, {
      withCredentials: true,
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown login error", error);
      throw error;
    });
};

const register = async (input: UserInput): Promise<AxiosResponse<User>> => {
  const undefinedIfEmpty = (s: string | undefined) =>
    s === "" || s === undefined ? undefined : s;
  const body = {
    name: undefinedIfEmpty(input.name),
    email: input.email,
    password: input.password,
    organization_name: undefinedIfEmpty(input.organization_name), // eslint-disable-line camelcase
    usecase: undefinedIfEmpty(input.usecase),
    other_usecase: undefinedIfEmpty(input.otherUsecase), // eslint-disable-line camelcase
    referrer: undefinedIfEmpty(input.referrer),
    tos_agreement: input.tosAgreement, // eslint-disable-line camelcase
  };
  const location = document.location;
  const verificationUrl = `${location.protocol}//${location.host}/verify`;

  const headers: Record<string, string> = {
    "content-type": "application/json",
  };
  if (process.env.REACT_APP_VALIDATE_EMAIL === "true") {
    headers["x-dwellir-verification-url"] = verificationUrl;
  }

  const res = await marlyApi
    .post<User>("/v4/auth/register", body, {
      headers,
      withCredentials: true,
    })
    .catch((error) => {
      if (isAxiosError<validationError>(error)) {
        if (
          error.response?.data.detail[0].msg ===
          "Value error, disposable_email_blocked"
        ) {
          throw new DisposableDomainBlocked();
        }
      }
      if (isAxiosError<validationError>(error)) {
        if (
          error.response?.data.detail[0].msg ===
          "Value error, Password should be at least 12 characters"
        ) {
          throw new PasswordToShort(error.response?.data.detail[0].msg, 12);
        }
      }
      if (isAxiosError<detailError>(error)) {
        if (error.response?.data.detail === "REGISTER_USER_ALREADY_EXISTS") {
          throw new UserExistsError(
            error.response?.data.detail || "Missing detail",
          );
        }
      }
      // Unknown error
      console.error("Unknown register error", error);
      throw error;
    });
  return res;
};

const sendVerificationEmail = async (): Promise<AxiosResponse<null>> => {
  const location = document.location;
  const verificationUrl = `${location.protocol}//${location.host}/verify`;
  return await marlyApi
    .post(
      "/email/send_verification",
      {
        // eslint-disable-next-line camelcase
        verification_url: verificationUrl,
      },
      {
        headers: { "content-type": "application/json" },
        withCredentials: true,
      },
    )
    .catch((error) => {
      // Unknown error
      console.error("Unknown sendVerificationEmail error", error);
      throw error;
    });
};

const verifyEmail = async (code: string): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .get(`/email/verify_code?code=${code}`, {
      withCredentials: true,
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown verifyEmail error", error);
      throw error;
    });
};

const currentUser = async (): Promise<AxiosResponse<User>> => {
  return await marlyApi
    .get("/v4/user", {
      withCredentials: true,
    })
    .then((backend) => {
      backend.data = recursiveToCamelCase(backend.data);
      return backend;
    });
};

const getHealthCheck = async (): Promise<AxiosResponse<null>> => {
  return await healthInstance.get("/health").catch((error) => {
    // Unknown error
    console.error("Unknown error", error);
    throw error;
  });
};

export default {
  login,
  access,
  logout,
  register,
  currentUser,
  sendVerificationEmail,
  verifyEmail,
  getHealthCheck,
};
