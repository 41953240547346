import { FC, useEffect } from "react";

export const OutsetaRegister: FC = () => {
  useEffect(() => {
    // Prod settings
    let planUid = "BWzr13WE";
    let authenticationCallbackUrl = null;
    let registrationConfirmationUrl = null;
    let postRegistrationUrl = null;

    // Settings override for staging/local
    if (window.location.hostname != "dashboard.dwellir.com") {
      let hostname = "dev-dashboard.dwellir.com";
      if (window.location.hostname == "local.dashboard.dwellir.com") {
        hostname = "local.dashboard.dwellir.com:8080";
      }
      authenticationCallbackUrl = `https://${hostname}/access`;
      registrationConfirmationUrl = `https://${hostname}/register`;
      postRegistrationUrl = `https://${hostname}/verify`;
      planUid = "NmdDLzm0";
    }
    window.o_options.auth = {
      id: "signup-embed",
      widgetMode: "register",
      mode: "embed",
      selector: "#register-embed",
      // planFamilyUid: "DQ2lXqWV",
      planUid,
      skipPlanOptions: true,
      authenticationCallbackUrl,
      registrationConfirmationUrl,
      postRegistrationUrl,
    };

    // Load the Outseta script with the defined options
    const scriptOutseta = document.createElement("script");
    scriptOutseta.src = "https://cdn.outseta.com/outseta.min.js";
    scriptOutseta.async = true;
    scriptOutseta.setAttribute("data-options", "o_options");
    document.head.appendChild(scriptOutseta);

    // Cleanup scripts on unmount
    return () => {
      document.head.removeChild(scriptOutseta);
    };
  }, []);

  return <div id="register-embed"></div>;
};
