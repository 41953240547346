import { useCallback, useEffect, useState } from "react";
import { Button } from "@mantine/core";
import { DownloadIcon } from "src/ui/icons/download-icon";

export const UpdateAvailable = () => {
  const currentVersion = process.env.GIT_VERSION;
  const [latestVersion, setLatestVersion] = useState(currentVersion);
  const [stopped, setStopped] = useState(false);

  const loadVersion = useCallback(async () => {
    const response = await fetch("/version.json");
    const { gitVersion } = await response.json();
    setLatestVersion(gitVersion);
    if (currentVersion != latestVersion) {
      // No need to continue fetching if we find a update
      setStopped(true);
    }
  }, [currentVersion, latestVersion]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined = undefined;
    if (!stopped) {
      interval = setInterval(loadVersion, 1000 * 60 * 10 /* 10 minutes */);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loadVersion, stopped]);

  const refresh = () => {
    window.location.reload();
  };

  if (currentVersion !== latestVersion) {
    return (
      <Button
        size="xs"
        onClick={refresh}
        leftSection={<DownloadIcon />}
        justify="left"
      >
        Update Dashboard
      </Button>
    );
  }
  return null;
};
