import { FC, ReactNode } from "react";
import { Text, Flex, Box, Paper } from "@mantine/core";
import { DwellirShield } from "src/ui/icons/dwellir-shield";
import { SellingPointCheckbox } from "src/ui/icons/selling-point-checkbox";
import { TextLink } from "src/ui/components/text-link";
import { MaintenanceModal } from "src/ui/components/maintenance-modal";
import { OutsetaRegister } from "src/ui/components/outseta/outseta-register";

interface SellingPointProps {
  label: string;
  children: ReactNode;
}

export const SellingPoint: FC<SellingPointProps> = ({ label, children }) => {
  return (
    <Box>
      <Flex direction="row" gap="xs">
        <Box w="rem(24)">
          <SellingPointCheckbox />
        </Box>
        <Flex direction="column">
          <Text size="md" fw={600}>
            {label}
          </Text>
          <Text size="sm" fw={400}>
            {children}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export const Register = () => {
  return (
    <Box w="100dvw" h="100dvh" bg="background">
      <MaintenanceModal />
      <Flex h="100vh" justify="center" align="center" p="sm" gap="xl">
        <Box visibleFrom="md">
          <Flex direction="column" w={400} gap="xl">
            <Flex direction="row" w="100%" gap="rem(2)" justify="center">
              <DwellirShield />
              <Text size="xl" fw={600}>
                Blockchain API Platform
              </Text>
            </Flex>
            <Flex direction="column" gap="lg">
              <SellingPoint label="No Compute Units here">
                1 Response = 1 API Credit. We find Compute unit pricing as
                confusing as you do.
              </SellingPoint>
              <SellingPoint label="Super High Rate Limits">
                Handle up to 10x requests per second with our Bursts Feature.
              </SellingPoint>
              <SellingPoint label="Personal Support">
                Gustav, Shanni, and Elias are here for you. Use the chat,{" "}
                <TextLink target="https://cal.com/dwellir/feedback">
                  schedule a call
                </TextLink>
                , or{" "}
                <TextLink target="mailto:support@dwellir.com">
                  email us
                </TextLink>
                .
              </SellingPoint>
            </Flex>
            <Paper radius="md" p="sm" withBorder>
              <center>
                <Text size="md" fw={400}>
                  Did you know? We also offer dedicated nodes!
                </Text>
                <Flex direction="column" w="100%" align="center">
                  <Text size="md" fw={400}>
                    <TextLink target="https://dwellir.outseta.com/forms/aWxXLVWV">
                      Contact us
                    </TextLink>{" "}
                    for a quote.
                  </Text>
                </Flex>
              </center>
            </Paper>
          </Flex>
        </Box>
        <Flex direction="column" w={400} gap="rem(10)">
          <OutsetaRegister />
        </Flex>
      </Flex>
    </Box>
  );
};
