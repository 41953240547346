import { TextComponent } from "../text-component";
import { EndpointCard } from "../endpoint-card";
import classes from "./get-started-grid.module.css";
import { useParams } from "react-router-dom";
import { Flex, Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const GetStartedGrid = () => {
  const { t } = useTranslation();
  const { id: endpointId } = useParams(); // Capture endpointId from URL

  // useParams returns string | undefined but we need string | null
  // const pathId = endpointId ?? null;

  return (
    <Flex direction={"column"} mt="md" mx={{ lg: "5vw", xl: "15vw" }}>
      <Grid gutter={"xl"}>
        <Grid.Col span={{ base: 12, xl: 9 }}>
          <div className={classes.text1}>
            <TextComponent
              title={t("get_started.text_1.title")}
              text={t("get_started.text_1.text")}
            />
          </div>
          {endpointId && <EndpointCard endpointName={endpointId} />}
          {/*
          <div className={classes.text2}>
            <TextComponent
              title={t("get_started.text_2.title")}
              text={t("get_started.text_2.text")}
            />
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xl: 9 }}>
          <TextCard
            title={t("get_started.textcard_3.title")}
            text={t("get_started.textcard_3.text")}
            button={{
              text: t("get_started.textcard_3.button.text"),
              link: generatePath(PageRoute.ENDPOINT_TABS, {
                id: pathId,

                tabValue: "get-started",
              }),
            }}
          />
        */}
        </Grid.Col>
      </Grid>
    </Flex>
  );
};
