import { FC, useEffect, useState } from "react";
import { Select, SelectProps, Text } from "@mantine/core";
import { ApiKey } from "src/api/api_keys";

export interface SelectApiKeysProps {
  hideLabel?: boolean;
  apiKeys: ApiKey[];
  selectedApiKey: ApiKey | null;
  onSelect: (key: ApiKey) => void;
  allowAllOption?: boolean;
}

// Fake key that marks filtering on all keys.
export const ALL_KEYS = {
  id: -1,
  name: "All keys",
  type: "",
  version: "",
  userId: "",
  apiKey: "All keys", // Same as the name for nicer rendering in the dropdown
  createdAt: "",
  updatedAt: "",
  enabled: true,
  manualEnabled: true,
  status: "enabled",
  dailyQuota: null,
  monthlyQuota: null,
};

export const SelectApiKey: FC<SelectApiKeysProps> = ({
  hideLabel,
  apiKeys,
  selectedApiKey,
  onSelect,
  allowAllOption = false,
}) => {
  const [internalKeys, setInternalKeys] = useState<ApiKey[]>(apiKeys);

  useEffect(() => {
    if (apiKeys) {
      if (allowAllOption) {
        setInternalKeys([ALL_KEYS, ...apiKeys]);
      }
    }
  }, [apiKeys, allowAllOption, setInternalKeys]);

  const renderSelectOption: SelectProps["renderOption"] = ({ option }) =>
    option.label !== option.value ? (
      <Text>
        <Text span fw={700}>
          {option.label}
        </Text>{" "}
        - {option.value}
      </Text>
    ) : (
      <Text>{option.value}</Text>
    );

  return (
    <Select
      id="select-api-key"
      label={hideLabel ? undefined : "Select API key"}
      placeholder="Select an API key"
      withCheckIcon={false}
      searchable
      comboboxProps={{ width: 370 }}
      onChange={(value) => {
        const newApiKey =
          internalKeys.find((key) => key.apiKey === value) || null;

        if (selectedApiKey?.apiKey === newApiKey?.apiKey || newApiKey == null) {
          return; // Do not change if the selected key is the same as the current one
        }

        onSelect(newApiKey); // Set the new API key if it's different
      }}
      data={internalKeys.map((key) => ({
        label: key.name || key.apiKey,
        value: key.apiKey,
      }))}
      renderOption={renderSelectOption}
      value={selectedApiKey?.apiKey}
    />
  );
};
