import {
  StripeElementsOptions,
  StripeError,
  loadStripe,
} from "@stripe/stripe-js";
import { FC, FormEvent, ReactNode, useEffect, useState } from "react";
import { Loading } from "../loading";
import { DangerButton, SubmitButton } from "../buttons";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import checkoutApi, { PaymentMethodCard } from "src/api/checkout";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Flex,
  Paper,
  Radio,
  Text,
  Timeline,
} from "@mantine/core";
import { useSubscriptionPreviews } from "src/store/subscription";
import { PreviewInvoice, PreviewKey } from "src/api/subscription_plan";
import { useTranslation } from "react-i18next";
import { usePaymentMethods } from "src/store/checkout";
import { capitalizeFirstLetter } from "src/utility/utils";
import { CreditCardIcon } from "src/ui/icons/credit-card-icon";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

interface PaymentMethodCardsProps {
  cards: PaymentMethodCard[];
  onSelect: (paymentMethodId: string) => void;
}

const defaultSelectedCardIndex = (cards: PaymentMethodCard[]): number => {
  const index = cards.findIndex((card) => card.default);
  if (index > -1) {
    return index;
  }
  let cardIndex = 0;
  for (const [index, card] of cards.entries()) {
    if (card.expirationYear > cards[index].expirationYear) {
      cardIndex = index;
    }
    if (card.expirationYear === cards[index].expirationYear) {
      if (card.expirationMonth > cards[index].expirationMonth) {
        cardIndex = index;
      }
    }
  }
  return cardIndex;
};

const PaymentMethodCards: FC<PaymentMethodCardsProps> = ({
  cards,
  onSelect,
}) => {
  // TODO Show expiration, default method pre-checked, show all payment
  // methods in the same Paper, send selected pm to the backend
  // if no default, pre-select the last one to expire
  const { t } = useTranslation();
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    defaultSelectedCardIndex(cards),
  );

  useEffect(() => {
    if (selectedCardIndex !== null) {
      onSelect(cards[selectedCardIndex].stripeId);
    }
  }, [onSelect, cards, selectedCardIndex]);

  const renderCard = (card: PaymentMethodCard, index: number) => {
    return (
      <Flex direction="row" w="100%" gap="xs" align="center">
        <Radio
          color="green"
          checked={selectedCardIndex === index}
          onChange={() => {
            setSelectedCardIndex(index);
          }}
        />
        <Flex direction="row" w="100%" justify="space-between" align="center">
          <Flex direction="row" align="center">
            <CreditCardIcon fillColor="green" />
            <Text>{capitalizeFirstLetter(card.displayBrand)}</Text>
          </Flex>
          <Flex direction="column" align="flex-end">
            <Flex direction="row" align="center">
              <Text>{card.last4}</Text>
            </Flex>
            <Text size="sm" c="dimmed" fw={400}>
              {t("format.cardExpires", {
                expiration: new Date(
                  card.expirationYear,
                  card.expirationMonth - 1,
                ),
                formatParams: {
                  expiration: { year: "numeric", month: "long" },
                },
              })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Paper
      shadow="lg"
      mt="md"
      p={{ base: "md", lg: "xl", xl: "xl" }}
      radius="md"
      withBorder
    >
      <Flex direction="column" w="100%" gap="sm">
        {cards.map((card, index) => {
          return <Box key={`card-${index}`}>{renderCard(card, index)}</Box>;
        })}
      </Flex>
    </Paper>
  );
};

interface PreviewProps {
  preview: PreviewInvoice;
  monthlyQuota: number;
}

const Preveiw: FC<PreviewProps> = ({ preview, monthlyQuota }) => {
  const { t } = useTranslation();

  if (preview.interval === "yearly") {
    return (
      <Timeline active={0} bulletSize={24} lineWidth={2}>
        <Timeline.Item title="Today" lineVariant="dashed">
          <Text size="sm">
            ${t("format.number", { number: preview.firstMonthFee / 100 })}{" "}
            {preview.interval} fee
          </Text>
          <Text size="sm">
            - $
            {t("format.number", {
              number: (preview.discountedAmount || 0) / 100,
            })}{" "}
            ({preview.discountedPercentage}% yearly discount)
          </Text>
          <Text size="sm">
            + ${preview.taxAmount / 100} (Tax ${preview.taxPercentage}%)
          </Text>
          <Divider />
          <Text size="sm" fw={700}>
            ${t("format.number", { number: preview.total / 100 })}
          </Text>
        </Timeline.Item>

        <Timeline.Item title="On the first of every month" lineVariant="dashed">
          <Text span fw={700} inherit>
            ${preview.overchargePrice} per million request
          </Text>{" "}
          <Text size="sm">
            exceeding {t("format.compact_number", { number: monthlyQuota })}
          </Text>
        </Timeline.Item>

        <Timeline.Item title="In a year">
          <Text size="sm">
            ${t("format.number", { number: preview.baseFee / 100 })}{" "}
            {preview.interval} fee
          </Text>
          <Text size="sm">
            - $
            {t("format.number", {
              number: (preview.discountedAmount || 0) / 100,
            })}{" "}
            ({preview.discountedPercentage}% yearly discount)
          </Text>
          <Text size="sm">
            + ${preview.taxAmount / 100} (Tax ${preview.taxPercentage}%)
          </Text>
          <Divider />
          <Text size="sm" fw={700}>
            ${t("format.number", { number: preview.total / 100 })}
          </Text>
        </Timeline.Item>
      </Timeline>
    );
  } else {
    return (
      <Timeline active={0} bulletSize={24} lineWidth={2}>
        <Timeline.Item title="Today" lineVariant="dashed">
          <Text size="sm">
            {"   "}$
            {t("format.number", { number: preview.firstMonthFee / 100 })}{" "}
            {preview.interval} fee
          </Text>
          <Text size="sm">
            + ${preview.taxAmount / 100} (Tax ${preview.taxPercentage}%)
          </Text>
          <Divider />
          <Text size="sm" fw={700}>
            {" "}
            ${t("format.number", { number: preview.total / 100 })}
          </Text>
        </Timeline.Item>

        <Timeline.Item title="On the first of every month" lineVariant="dashed">
          <Text size="sm">
            {"   "}${t("format.number", { number: preview.baseFee / 100 })}{" "}
            {preview.interval} fee
          </Text>
          <Text size="sm">
            + ${preview.taxAmount / 100} (Tax ${preview.taxPercentage}%)
          </Text>
          <Text span fw={700} inherit>
            + ${preview.overchargePrice} per million responses
          </Text>{" "}
          <Text size="sm">
            exceeding {t("format.compact_number", { number: monthlyQuota })}
          </Text>
        </Timeline.Item>
      </Timeline>
    );
  }
};

type checkoutFormProps = {
  lookupKey: string;
  onComplete: () => void;
  onCancel: () => void;
  plan: ReactNode;
  monthlyQuota: number;
};

const CheckoutForm: FC<checkoutFormProps> = ({
  lookupKey,
  onComplete,
  onCancel,
  plan,
  monthlyQuota,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const [yearly, setYearly] = useState(false);
  const previews = useSubscriptionPreviews();
  const paymentMethods = usePaymentMethods();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element &&
        element.on("ready", () => {
          setIsStripeLoaded(true);
        });
    }
  }, [elements]);

  const getPreviewKey = (
    lookupKey: string,
    yearly: boolean,
  ): PreviewKey | null => {
    if (lookupKey === "developer_plan" && yearly) {
      return "developerPlanYearly";
    }
    if (lookupKey === "developer_plan" && !yearly) {
      return "developerPlanMonthly";
    }
    if (lookupKey === "growth_plan" && yearly) {
      return "growthPlanYearly";
    }
    if (lookupKey === "growth_plan" && !yearly) {
      return "growthPlanMonthly";
    }
    return null;
  };

  const handleError = (error: StripeError) => {
    setLoading(false);
    error && setErrorMessage(error.message);
  };

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!(stripe || hasPaymentMethods)) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    // Trigger form validation and wallet collection
    if (elements) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }
    }

    // TODO this should change to a dedicated createSubscription action
    const { type, clientSecret, url } = await checkoutApi
      .createCheckout({
        lookupKey,
        yearly,
        paymentMethodId: selectedPaymentMethod,
      })
      .then((res) => res.data);

    if (type == "redirect") {
      window.location.replace(url);
    }

    // If this was a subscription change we don't need to confirm the
    // intent, not sure why.
    if (type !== "change") {
      if (!stripe) {
        return;
      }
      const confirmIntent =
        type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const confirm = await confirmIntent({
        elements: elements ?? undefined,
        clientSecret,
        confirmParams: {
          // TODO Need to set this redirect up properly and test it out by
          // removing the if_required redirect stuff just below this.
          return_url: "https://example.com/order/123/complete", // eslint-disable-line camelcase
        },
        redirect: "if_required",
      });

      if (confirm.error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        setLoading(false);
        return;
      } else {
        setLoading(false);
        onComplete();
        return;
      }
    }
    // Your customer is redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer is redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
    setLoading(false);
    onComplete();
  };

  const previewKey = getPreviewKey(lookupKey, yearly);
  const hasPaymentMethods =
    paymentMethods.state === "fulfilled" && paymentMethods.data.length > 0;

  return (
    <Loading
      isLoading={
        loading ||
        previews.state === "loading" ||
        paymentMethods.state === "loading"
      }
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        justify="center"
        gap="lg"
        mt="sm"
      >
        <Box visibleFrom="sm">{plan}</Box>
        {previews.state == "fulfilled" && previewKey && (
          <Preveiw
            preview={previews.data[previewKey]}
            monthlyQuota={monthlyQuota}
          />
        )}
        <Flex direction="column" justify="space-between">
          <form onSubmit={handleSubmit}>
            {(isStripeLoaded || hasPaymentMethods) && (
              <Card shadow="sm" padding="lg" radius="md" withBorder mb="md">
                <Checkbox
                  label="I want to pay yearly and get 30% off!"
                  onChange={(event) => setYearly(event.currentTarget.checked)}
                />
              </Card>
            )}
            {hasPaymentMethods ? (
              <PaymentMethodCards
                cards={paymentMethods.data}
                onSelect={setSelectedPaymentMethod}
              />
            ) : (
              <PaymentElement />
            )}
            {(isStripeLoaded || hasPaymentMethods) && (
              <Flex direction="row" justify="flex-end">
                <SubmitButton
                  text="Submit"
                  my="md"
                  disabled={!stripe || loading}
                />
              </Flex>
            )}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
          <Flex direction="column" align="flex-end">
            <DangerButton text="Cancel" my="md" onClick={onCancel} />
          </Flex>
        </Flex>
      </Flex>
    </Loading>
  );
};

type checkoutProps = {
  lookupKey: string;
  onComplete: () => void;
  onCancel: () => void;
  plan: ReactNode;
  planData: {
    id: number;
    amount: number;
    currency: string;
    monthlyQuota: number;
  };
};

export const Checkout: FC<checkoutProps> = ({
  lookupKey,
  onComplete,
  onCancel,
  plan,
  planData,
}) => {
  const options: StripeElementsOptions = {
    mode: "subscription",
    amount: planData.amount,
    currency: planData.currency,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
    loader: "always",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        lookupKey={lookupKey}
        onComplete={onComplete}
        plan={plan}
        onCancel={onCancel}
        monthlyQuota={planData.monthlyQuota}
      />
    </Elements>
  );
};
