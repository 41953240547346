import { FC, useState } from "react";
import classes from "./endpoints-search.module.css";
import { TextInput } from "@mantine/core";
import { SearchIcon } from "src/ui/icons/search-icon";

interface EndpointsSearchResultProps {
  showResults?: boolean;
  onChange: (filter: string) => void;
}

export const EndpointsSearchInput: FC<EndpointsSearchResultProps> = ({
  showResults = false,
  onChange,
}) => {
  const [filter, setFilter] = useState("");

  return (
    <TextInput
      classNames={{
        input: showResults ? classes.input : undefined,
      }}
      size="xl"
      placeholder="Search Endpoints"
      value={filter}
      onChange={(event) => {
        setFilter(event.currentTarget.value);
        onChange(event.currentTarget.value);
      }}
      leftSection={<SearchIcon />}
    />
  );
};
