import { FC, useEffect, useState } from "react";
import { TextComponent } from "../text-component";
import { useTranslation } from "react-i18next";
import { theme } from "src/ui/theme";
import { Chart } from "src/utility/chart";
import { BarChart } from "@mantine/charts";

interface MethodChartProps {
  chart: Chart;
}

export const MethodChart: FC<MethodChartProps> = ({ chart }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<object[]>([]);
  const [hosts, setHosts] = useState<string[]>([]);

  useEffect(() => {
    const hosts = new Set<string>();
    const metric = Object.entries(chart.methods)
      .map(([method, usage]) => {
        if (usage.requests === 0) {
          return;
        }
        return Object.assign(
          {
            name: method,
            requests: usage.requests, // used for sorting only
          },
          ...usage.byHost.map((usage) => {
            hosts.add(usage.hostName);
            return {
              [usage.hostName]: usage.requests,
            };
          }),
        );
      })
      .filter((m) => m != null);
    metric.sort((a, b) => b.requests - a.requests);
    setData(metric);
    setHosts([...hosts]);
  }, [chart, setData]);

  return (
    <>
      <TextComponent title={t("metrics.method_table.title")} mb="sm" />
      <BarChart
        h={700}
        data={data}
        dataKey="name"
        type="stacked"
        orientation="vertical"
        yAxisProps={{ width: 80 }}
        valueFormatter={(value) => {
          if (typeof value !== "number") {
            return value;
          }
          return t("format.compact_number", { number: value });
        }}
        series={hosts.map((hostName, idx) => {
          return {
            name: hostName,
            color: theme.colors?.green?.[(idx % 6) + 4],
          };
        })}
        tickLine="xy"
        withLegend
        legendProps={{
          verticalAlign: "bottom",
          height: 100,
          wrapperStyle: {
            overflow: "auto",
          },
        }}
      />
    </>
  );
};
