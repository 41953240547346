import { FC, useEffect, useState } from "react";
import { ALL_CHAINS } from "../select-chain";
import { Loading } from "../loading";
import { Paper } from "@mantine/core";
import { Interval } from "src/api/user";
import { theme } from "src/ui/theme";
import {
  Chart,
  dayKeySort,
  initChart,
  populateV4Analytics,
} from "src/utility/chart";
import { useTranslation } from "react-i18next";
import { Chain } from "src/api/chain";
import { useChains } from "src/store/chain";
import { ApiKey } from "src/api/api_keys";
import { useAnalyticsV4 } from "src/store/user";
import { BarChart } from "@mantine/charts";

interface UsageChartProps {
  interval: Interval;
  daysBack: number;
  apiKeys?: ApiKey | null;
  chain?: Chain;
  label?: string;
  chart?: Chart;
  height?: string;
}

function formatDateToShortMonth(dateString: string) {
  const [day, month] = dateString.split("/");
  const date = new Date(2000, parseInt(month, 10) - 1, parseInt(day, 10));
  return date.toLocaleDateString("en-US", { day: "numeric", month: "short" });
}

interface MetricData {
  name: string;
  hosts: string[];
  [key: string]: number | string | string[];
}

export const UsageChart: FC<UsageChartProps> = ({
  apiKeys = null,
  interval,
  daysBack,
  chain = undefined,
  label = "responses",
  chart = undefined,
  height = "300px",
}) => {
  const { t } = useTranslation();
  const analytics = useAnalyticsV4(interval);
  const [data, setData] = useState<MetricData[]>([]);
  const [hostNames, setHostNames] = useState<string[]>([]);
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [filterApiKeys, setFilterApiKeys] = useState<string[] | undefined>(
    undefined,
  );
  const [filterDomains, setFilterDomains] = useState<string[] | undefined>(
    undefined,
  );
  const chains = useChains();

  useEffect(() => {
    if (chain?.id !== ALL_CHAINS.id) {
      setFilterDomains(
        chain?.networks.map((network) => {
          const url = new URL(network.https);
          return url.host;
        }),
      );
    } else {
      setFilterDomains(undefined);
    }
  }, [chain]);

  useEffect(() => {
    if (apiKeys) {
      setFilterApiKeys([apiKeys.apiKey]);
    } else {
      setFilterApiKeys(undefined);
    }
  }, [apiKeys]);

  useEffect(() => {
    if (analytics.state === "fulfilled" && chains.state === "fulfilled") {
      const data = analytics.data;
      const internalChart = initChart(interval, daysBack, () =>
        process.env.REACT_APP_INJECT_RANDOM_DATA === "true"
          ? Math.floor(100000 + Math.random() * 100000)
          : 0,
      );

      const lookupHostName = new Map();

      // TODO remove hardcoded dev values
      lookupHostName.set("dev-api-westend.dwellir.com", "Westend");
      lookupHostName.set("dev-api-polkadot.dwellir.com", "Polkadot");
      chains.data.map((chain) => {
        chain.networks.map((network) => {
          const url = new URL(network.https);
          const host = url.host;
          const hostName = `${chain.name} - ${network.name}`;
          lookupHostName.set(host, hostName);
        });
      });

      populateV4Analytics(
        internalChart,
        data,
        lookupHostName,
        filterApiKeys,
        filterDomains,
      );
      let metric;
      const hostNames = new Set<string>();
      if (chain) {
        metric = Object.entries(internalChart.data).map(([date, usage]) => {
          return Object.assign(
            {
              name: date,
            },
            internalChart.isEmpty && { [label]: 0 },
            ...usage.byHost.map((usage) => {
              hostNames.add(usage.hostName);
              return {
                [usage.hostName]: usage.responses,
              };
            }),
          );
        });
        internalChart.isEmpty && hostNames.add(label);
        setShowLegend(true);
      } else {
        metric = Object.entries(internalChart.data).map(([date, usage]) => ({
          name: date,
          [label]: usage.responses,
        }));
        hostNames.add(label);
        setShowLegend(false);
      }
      metric.sort(dayKeySort);
      metric = metric.map((m) => {
        m.name = formatDateToShortMonth(m.name);
        return m;
      });
      setData(metric);
      setHostNames([...hostNames]);
    }
  }, [
    analytics,
    chain,
    chains,
    chart,
    daysBack,
    filterApiKeys,
    filterDomains,
    interval,
    label,
  ]);

  return (
    <Loading isLoading={analytics.state === "loading"}>
      <BarChart
        h={height}
        data={data}
        dataKey="name"
        type="stacked"
        valueFormatter={(value) =>
          t("format.compact_number", { number: value })
        }
        series={hostNames.map((hostName, idx) => {
          return {
            name: hostName,
            color: theme.colors?.green?.[(idx % 6) + 4],
          };
        })}
        tickLine="xy"
        withLegend={showLegend}
        legendProps={{
          verticalAlign: "bottom",
          height: 100,
          wrapperStyle: {
            overflow: "auto",
          },
        }}
      />
    </Loading>
  );
};

interface UsageChartCardProps {
  chain?: Chain;
  apiKeys?: ApiKey | null;
  height?: string;
}

export const UsageChartCard: FC<UsageChartCardProps> = ({
  chain = undefined,
  apiKeys = null,
  height,
}) => {
  return (
    <Paper
      shadow="lg"
      mt="md"
      p={{ base: "md", lg: "xl", xl: "xl" }}
      radius="md"
      h="40vh"
      withBorder
    >
      <UsageChart
        apiKeys={apiKeys}
        interval="day"
        daysBack={30}
        chain={chain}
        height={height}
      />
    </Paper>
  );
};
