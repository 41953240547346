import { FC, useEffect } from "react";

export const OutsetaLogin: FC = () => {
  useEffect(() => {
    let authenticationCallbackUrl = null;
    if (window.location.hostname == "local.dashboard.dwellir.com") {
      authenticationCallbackUrl =
        "https://local.dashboard.dwellir.com:8080/access";
    }
    window.o_options.auth = {
      widgetMode: "login",
      id: "login-embed",
      mode: "embed",
      selector: "#login-embed",
      authenticationCallbackUrl,
    };

    // Load the Outseta script with the defined options
    const scriptOutseta = document.createElement("script");
    scriptOutseta.src = "https://cdn.outseta.com/outseta.min.js";
    scriptOutseta.async = true;
    scriptOutseta.setAttribute("data-options", "o_options");
    document.head.appendChild(scriptOutseta);

    // Cleanup scripts on unmount
    return () => {
      document.head.removeChild(scriptOutseta);
    };
  }, []);

  return <div id="login-embed"></div>;
};
