import { useEffect } from "react";
import { Loading } from "../components/loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { access, selectAccessRequestState } from "src/store/user";
import { useAppDispatch } from "src/core/hooks";
import { useSelector } from "react-redux";
import { Box, Flex } from "@mantine/core";
import { PageRoute } from "src/utility/utils";

export const AccessPage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const accessRequest = useSelector(selectAccessRequestState);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = searchParams.get("access_token");
    if (accessToken) {
      dispatch(access(accessToken));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (accessRequest.state === "fulfilled") {
      if (accessRequest.data.first_time) {
        navigate(PageRoute.REQUIRED_DATA);
      } else {
        navigate(PageRoute.INDEX);
      }
    }
  }, [accessRequest, navigate]);

  return (
    <Flex
      h="100vh"
      w="100vw"
      direction="column"
      align="center"
      justify="center"
      gap="md"
    >
      <Loading isLoading={true}>
        <Box h="100dvh" w="100dvw" />
      </Loading>
    </Flex>
  );
};
