import { ReactNode, forwardRef } from "react";
import {
  createPolymorphicComponent,
  ButtonProps as MantineButtonProps,
  Button as MantineButton,
  Space,
  Tooltip,
} from "@mantine/core";

type ButtonProps = {
  id?: string;
  text: string;
  onClick?: () => void;
  icon?: ReactNode;
  tooltip?: string;
} & MantineButtonProps;

export const Button = createPolymorphicComponent<"button", ButtonProps>(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLButtonElement, ButtonProps>(
    ({ id, text, styles, onClick, icon, tooltip, ...buttonProps }, ref) => {
      const button = (
        <MantineButton
          id={id}
          ref={ref}
          styles={styles}
          variant={buttonProps.variant ? buttonProps.variant : "filled"}
          onClick={onClick}
          {...buttonProps}
        >
          {icon && <Space pr={"xs"}>{icon}</Space>}
          {text}
        </MantineButton>
      );

      if (tooltip) {
        return <Tooltip label={tooltip}>{button}</Tooltip>;
      } else {
        return button;
      }
    },
  ),
);
