import { FC, useEffect, useMemo } from "react";
import { PostHogProvider } from "posthog-js/react";

import { posthog } from "posthog-js";
import { theme } from "../../theme";
import {
  CSSVariablesResolver,
  MantineProvider,
  getThemeColor,
  rgba,
} from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { router } from "src/utility/routes";
import { marlyApi } from "src/api/marly";
import { useAppDispatch } from "src/core/hooks";
import { setMaintenanceMode } from "src/store/maintenance";
import { useUser } from "src/store/user";

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    "--mantine-color-body": getThemeColor("background", theme),
    // Action icon color
    "--ai-color": getThemeColor("background", theme),

    // Mantine uses 12% alpha by default, in figma it is 20%
    "--mantine-color-green-light-hover": rgba(
      getThemeColor("green", theme),
      0.2,
    ),
  },
  dark: {
    "--mantine-color-body": getThemeColor("background", theme),
    "--ai-color": getThemeColor("background", theme),
  },
});

export const App: FC = () => {
  const postHogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST, //eslint-disable-line
  };
  const user = useUser();

  useEffect(() => {
    if (user.state === "fulfilled" && user.data.isVerified) {
      posthog.identify(user.data.id, {
        email: user.data.email,
        name: user.data.name,
      });
    }
  }, [user]);

  const dispatch = useAppDispatch();
  useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    marlyApi.interceptors.response.use(undefined, (error: any) => {
      const status = error.response?.status;
      const detail = error.response?.data?.detail;
      const start = error.response?.data?.start;
      const expectedEnd = error.response?.data?.expected_end;
      if (status === 503 && detail === "Service is down for maintenance") {
        dispatch(
          setMaintenanceMode({
            inMaintenance: true,
            start,
            expectedEnd,
          }),
        );
      }
      return Promise.reject(error);
    });
  }, [dispatch]);

  return (
    <MantineProvider
      theme={theme}
      cssVariablesResolver={resolver}
      defaultColorScheme="auto"
    >
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
      >
        <RouterProvider router={router} />
      </PostHogProvider>
    </MantineProvider>
  );
};
