export const DownloadIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.36819 2 8.66667 2.29848 8.66667 2.66667V9.05719L10.8619 6.86193C11.1223 6.60158 11.5444 6.60158 11.8047 6.86193C12.0651 7.12228 12.0651 7.54439 11.8047 7.80474L8.4714 11.1381C8.21105 11.3984 7.78894 11.3984 7.5286 11.1381L4.19526 7.80474C3.93491 7.54439 3.93491 7.12228 4.19526 6.86193C4.45561 6.60158 4.87772 6.60158 5.13807 6.86193L7.33333 9.05719V2.66667C7.33333 2.29848 7.63181 2 8 2ZM2.66667 10.6667C3.03486 10.6667 3.33333 10.9651 3.33333 11.3333V12.6667C3.33333 12.8435 3.40357 13.013 3.5286 13.1381C3.65362 13.2631 3.82319 13.3333 4 13.3333H12C12.1768 13.3333 12.3464 13.2631 12.4714 13.1381C12.5964 13.013 12.6667 12.8435 12.6667 12.6667V11.3333C12.6667 10.9651 12.9651 10.6667 13.3333 10.6667C13.7015 10.6667 14 10.9651 14 11.3333V12.6667C14 13.1971 13.7893 13.7058 13.4142 14.0809C13.0391 14.456 12.5304 14.6667 12 14.6667H4C3.46957 14.6667 2.96086 14.456 2.58579 14.0809C2.21071 13.7058 2 13.1971 2 12.6667V11.3333C2 10.9651 2.29848 10.6667 2.66667 10.6667Z"
        fill="white"
      />
    </svg>
  );
};
