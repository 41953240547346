import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface OrganizationInformation {
  name: string | undefined;

  billingCountry: string | undefined;
  billingAddress1: string | undefined;
  billingAddress2: string | undefined;
  billingPostalCode: string | undefined;
  billingCity: string | undefined;
  billingPhone: string | undefined;

  isBusiness: boolean | undefined;
  stripeTaxIdType: string | undefined;
  taxId: string | undefined;

  canChangeTaxId: boolean;
}

export interface OutsetaAddress {
  uid: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressLine3: string | undefined;
  city: string | undefined;
  state: string | undefined;
  postalCode: string | undefined;
  country: string | undefined;
  created: string | undefined;
  updated: string | undefined;
}

export interface OutsetaAccount {
  uid: string;
  name: string | undefined;
  usecase: string | undefined;
  otherUsecase: string | undefined;
  referrer: string | undefined;
  taxId: string | undefined;
  taxIdType: string | undefined;
  billingAddress: OutsetaAddress | undefined;
}

const createOrganizationInformation = async (
  input: OrganizationInformation,
): Promise<AxiosResponse<OrganizationInformation>> => {
  return await marlyApi
    .put(
      "/v4/organization/information",
      {
        name: input.name,
        billing_country: input.billingCountry, // eslint-disable-line camelcase
        billing_address_1: input.billingAddress1, // eslint-disable-line camelcase
        billing_address_2: input.billingAddress2, // eslint-disable-line camelcase
        billing_postal_code: input.billingPostalCode, // eslint-disable-line camelcase
        billing_city: input.billingCity, // eslint-disable-line camelcase
        billing_phone: input.billingPhone, // eslint-disable-line camelcase
        is_business: input.isBusiness, // eslint-disable-line camelcase
        stripe_tax_id_type: input.stripeTaxIdType, // eslint-disable-line camelcase
        tax_id: input.taxId, // eslint-disable-line camelcase
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const getOrganizationInformation = async (): Promise<
  AxiosResponse<OrganizationInformation>
> => {
  return await marlyApi
    .get("/v4/organization/information", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const updateOrganizationInformation = async (
  update: OrganizationInformation,
): Promise<AxiosResponse<OrganizationInformation>> => {
  // Create and update use the same put endpoint
  return createOrganizationInformation(update);
};

const getOutsetaAccount = async (): Promise<AxiosResponse<OutsetaAccount>> => {
  return await marlyApi
    .get("/v4/organization/information/outseta", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const updateOutsetaAccount = async (
  update: OutsetaAccount,
): Promise<AxiosResponse<OutsetaAccount>> => {
  return await marlyApi
    .put(
      "/v4/organization/information/outseta",
      {
        uid: update.uid,
        name: update.name,
        usecase: update.usecase,
        other_usecase: update.otherUsecase, // eslint-disable-line camelcase
        referrer: update.referrer,
        tax_id: update.taxId, // eslint-disable-line camelcase
        tax_id_type: update.taxIdType, // eslint-disable-line camelcase

        // eslint-disable-next-line camelcase
        billing_address: {
          address_line1: update.billingAddress?.addressLine1, // eslint-disable-line camelcase
          address_line2: update.billingAddress?.addressLine2, // eslint-disable-line camelcase
          address_line3: update.billingAddress?.addressLine3, // eslint-disable-line camelcase
          city: update.billingAddress?.city,
          state: update.billingAddress?.state,
          postal_code: update.billingAddress?.postalCode, // eslint-disable-line camelcase
          country: update.billingAddress?.country,
          uid: update.billingAddress?.uid,
          created: update.billingAddress?.created,
          updated: update.billingAddress?.updated,
        },
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

export default {
  createOrganizationInformation,
  getOrganizationInformation,
  updateOrganizationInformation,
  getOutsetaAccount,
  updateOutsetaAccount,
};
